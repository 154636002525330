const axios = require("axios")
export const config = {
  demoName: "AugmentedSeller",
  pwaVersion: "1.1.1",

  shopName: "Worldline",
  orderOrigin: "AugmentedSeller",

  needIdentification: false,

  WS_URL:`https://weshop-ws-v2-weshop.pubqlf.co.as8677.net`,
  CUSTO_WS_URL:`https://wldd-yumi-demonstrations-wldd-tools.pubqlf.co.as8677.net`,

};

export function isNotNull(val){
  if(val != null && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1 && val !== "[]"){
      if(!Array.isArray(val)){
          return true;
      }else if(val.length > 0){
          return true;
      }
  }
  return false;
}

export function isNull(val){
  if(val == null || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1 || val === "[]"){
      return true;
  }else{
      if(!Array.isArray(val)){
          return false;
      }else if(val.length === 0){
          return true;
      }
  }
  return false;
}

export function getSteps(){
    return [
        {
            id: 1,
            label: 'basketStep',
            icon: "mdi-cart-outline"
        },
        {
            id: 2,
            label: 'paymentStep',
            icon: "mdi-credit-card-outline"
        },
        {
            id: 3,
            label: 'confirmationStep',
            icon: "mdi-check-circle-outline"
        }
    ]
}
export function getDeviceType(){
    let userAgent = navigator.userAgent || navigator.vendor || window.opera
    userAgent = userAgent.toLowerCase()

    if (/android/i.test(userAgent)) {
        return "android";
    }

    if ((/ipad|iphone|ipod/.test(userAgent) || /^((?!chrome|android).)*safari/i.test(userAgent)) && !window.MSStream) {
        return "ios";
    }

    return "other";
}

export function saveDeviceType(){
    let deviceType;
    let usrAgent = navigator.userAgent.toLowerCase();
    if((usrAgent.indexOf('iphone')>-1 || usrAgent.indexOf('ipad')>-1 || /^((?!chrome|android).)*safari/i.test(usrAgent) )){
        deviceType = "iphone";
    }else if(usrAgent.indexOf('android')>-1 ){
        deviceType = "android";
    }else{
        deviceType = "other";
    }

    let savedType = localStorage.getItem("IEC_deviceType") || null

    if(!savedType || deviceType != savedType){
        localStorage.setItem("IEC_deviceType", deviceType)
    }
}
