const paymentMethods = [
    {
        id: 3,
        name: "paymentMethodApplePay",
        icon: "icon-applePay",
        description: "",
        componentName: "ApplePay",
        nextRoute: "/ticket",
        sorting: 10,
        config: {
            version: 4,
            merchantId: "merchant.com.worldline.dsp.wldd.demo",
            domainName: "wl-dsp-pay-and-drive.pubqlf.co.as8677.net",
            displayName: "Pay And Drive",
            merchantCapabilities: [ "supports3DS" ],
            supportedNetworks: [ "visa", "masterCard", "amex", "discover", "cartesBancaires", "electron", "vPay", "maestro" ],
        },
        compatibleDevices: [
            "ios"
        ]
    },
    {
        id: 4,
        name: "paymentMethodGooglePay",
        icon: "icon-googlePay",
        description: "",
        componentName: "GooglePay",
        nextRoute: "/ticket",
        sorting: 20,
        config: {
            version: 2,
            minVersion: 0,
            supportedNetworks: ['MASTERCARD', 'VISA'],
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            gatewayConfig: {
                gateway: "stripe",
                "stripe:version": "2018-11-08",
                "stripe:publishableKey": "pk_test_19ow8cjRvtMRxKzIVyTwOEam"
            },
            merchantInfo:{
                merchantId: '0123456789',
                merchantName: 'App Name'
            }
        },
        compatibleDevices: [
            "android",
            "other"
        ]
    },
    {
        id: 5,
        name: "paymentMethodCreditCard",
        icon: "icon-phone-card",
        description: "",
        componentName: null,
        nextRoute: {name: 'PayPage', query: {customerPayment: true}},
        sorting: 90,
        config: null,
        compatibleDevices: [
            "android",
            "ios",
            "other"
        ]
    }
]

export function getPaymentMethodsByDevice(deviceType){
    return paymentMethods.filter(paymentMethod => paymentMethod.compatibleDevices.includes(deviceType.toLowerCase())).sort((p1, p2) => p2.sorting - p1.sorting)
}
export function getAllPaymentMethods(){
    return paymentMethods
}