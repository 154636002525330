<template>
    <div id="ev-charging">
        <div class="mt-3 d-flex flex-column align-center">
            <v-icon class="my-2" size="64" color="light-green darken-1">mdi-ev-station</v-icon>
            <p class="mb-0 font-weight-bold text-body-1 light-green--text text--darken-1"> <v-icon color="light-green darken-1">mdi-map-marker-radius-outline</v-icon> Parking avenue Foch</p>
            <p class="mb-0 font-weight-bold text-body-1 light-green--text text--darken-1">Emplacement 1</p>
            <p class="mb-0 font-weight-bold text-body-1 ">{{$t("evChargingCost")}}: {{availablePlugs[selectedPlugId].price}}€ / min</p>
        </div>

        <div class="d-flex flex-column" v-if="step === 1">
            <div class="d-flex flex-column align-center">
                <p class="mb-0 deep-purple--text text--accent-4 text-center font-weight-bold text-h2">60%</p>
                <p class="mb-0 deep-purple--text text--accent-4 font-italic text-center font-weight-bold text-h5">{{$t("evChargingDiscountText1")}}</p>
                <p class="mb-0 text-center font-weight-bold text-body-1">{{$t("evChargingDiscountText2")}}</p>
            </div>

            <div class="my-4 px-4 d-flex align-center flex-column chargers-wrapper">
                <p class="text-center font-weight-bold text-body-1 align-self-start">{{$t("evChargingSelectChargeType")}}:</p>
                <div class="px-3 d-flex align-center justify-center">
                    <div v-for="plug in availablePlugs" :key="`plug-${plug.id}`" :class="{'mx-4 px-5 py-4 d-flex flex-column rounded-xl charger-wrapper': true, 'active': plug.id == selectedPlugId}" @click="selectedPlugId = plug.id">
                        <span class="text-caption">{{plug.power}}kW</span>
                        <span class="text-caption">{{plug.title}}</span>
                        <span class="text-caption">{{plug.price}}€/min</span>
                    </div>
                </div>
                <v-slider class="my-3 slider align-center" @input="catchInput" width="100%" :key="`slider-${sliderKey}`" v-model="duration" :tick-labels="durations" :max="4" step="1" ticks="always" tick-size="5" hide-details>
                    <template v-slot:append>
                        <p class="ma-0">100%</p>
                    </template>
                </v-slider>
            </div>

            <p class="text-center font-weight-bold text-body-1">{{$t("evChargingEstimatedAmount")}} : {{estimatedAmount}}€</p>

            <v-btn class="align-self-center" color="deep-purple accent-4" dark width="80%" @click="step = 2">{{$t("evChargingEnable")}}</v-btn>
        </div>

        <div class="pa-3 d-flex flex-column" v-if="step === 2">
            <p class="mt-3 font-weight-medium text-h6 text-center">
                {{$t("evChargingPreAuth", {estimatedAmount: estimatedAmount})}}
            </p>
            <p class="mb-0 text-body-1 text-center">
                {{$t("evChargingPreAuthDiscount")}}
            </p>
            <div class="mx-4 payment-methods-wrapper d-flex flex-column">
                <v-radio-group v-model="selectedPaymentMethod" class="ma-0" dense hide-details>
                    <PaymentMethodListBloc v-for="(paymentMethod, key) in paymentMethodsToShow" :key="`payment-method-${key}`" :paymentMethod="paymentMethod" @paymentMethodChanged="selectedPaymentMethod = $event"></PaymentMethodListBloc>
                </v-radio-group>
                <v-checkbox class="custom-check-btn my-0 align-self-center" v-model="generalTermsAccepted" hide-details>
                    <template v-slot:label>
                        <span class="primary--text text-caption">{{$t('agreeTermsPaymentMethods')}}</span>
                    </template>
                </v-checkbox>
                <div class="mt-3 d-flex flex-column align-center justify-center payment-methods-footer">
                    <span class="my-1 text-center text-caption red--text text--darken-2" v-if="payBtnClicked && !generalTermsAccepted">{{$t("warningPaymentMethods")}}</span>
                    <template v-if="generalTermsAccepted && selectedPaymentMethodObject && selectedPaymentMethodObject.componentName != null">
                        <component :is="selectedPaymentMethodObject.componentName" :amount="estimatedAmount" :paymentConfig="selectedPaymentMethodObject.config" :nextRoute="selectedPaymentMethodObject.nextRoute" @paymentDone="step = 3"></component>
                    </template> 
                    <v-btn v-else class="mt-4 align-self-center payment-methods-footer-btn" :class="{'disabled': !generalTermsAccepted || !selectedPaymentMethodObject}" color="deep-purple accent-4" dark @click="handleCheckoutClick(selectedPaymentMethodObject)">{{$t("prepayBtn")}}</v-btn>
                </div>
            </div>
        </div>

        <div v-if="step === 3" class="d-flex flex-column text-center align-center">
            <p class="mt-3 font-weight-medium text-h6 text-center">
                {{$t("evChargingPreAuthCheck")}}
            </p>
            <p class="text-body-1 text-center">
                {{$t("evChargingPlugCar")}}
            </p>
            <v-icon class="my-2" size="100" color="light-green darken-1">mdi-connection</v-icon>
            <v-btn color="deep-purple accent-4" @click="handleCarPlugged()" dark>{{$t("evChargingCarPlugged")}}</v-btn>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ApplePay from "@/components/payment-methods/ApplePay";
import PaymentMethodListBloc from "@/components/PaymentMethodListBloc";
import GooglePay from "@/components/payment-methods/GooglePay";

import { getPaymentMethodsByDevice } from "@/assets/js/PaymentMethods"
import { getDeviceType } from "@/assets/js/Utils"

export default {
    name: 'EVCharging',
    components: {
        ApplePay,
        PaymentMethodListBloc,
        GooglePay
    },
    data(){
        return {
            step: 1,
            page: {
                title: "evChargingTitle",
                description: null
            },
            selectedPlugId: 0,
            duration: 1,
            durations: ['', '60 min', '120 min', '180 min', ''],
            payBtnClicked: false,
            generalTermsAccepted: false,
            selectedPaymentMethod: -1,
            paymentMethods: getPaymentMethodsByDevice(getDeviceType()),
            showCharging: false,
            sliderKey: 0
        }
    },
    computed:{
        ...mapGetters({
            paymentDone: "paymentDone",
            basketTotalAmount: "basket/totalAmount",
            formattedPins: "pins/formattedPins",
        }),
        availablePlugs(){
            return [
                {
                    id: 0,
                    title: "TYPE 2",
                    power: 22,
                    price: 0.247
                },
                {
                    id: 1,
                    title: "COMBO CCS",
                    power: 27,
                    price: 0.370
                }
            ]
        },
        estimatedAmount(){
            return this.formatPrice(this.availablePlugs[this.selectedPlugId].price * (this.duration * 60))
        },
        selectedPaymentMethodObject(){
            if(this.selectedPaymentMethod > -1){
                return this.paymentMethods.find(paymentMethod => this.selectedPaymentMethod == paymentMethod.id )
            }
            return null
        },
        canPay(){
            return this.generalTermsAccepted
        },
        paymentMethodsToShow(){
            return this.paymentMethods
        },
        chargeDuration(){
            return this.duration > 0 ? this.duration * 60 : null
        }
    },
    mounted() {
    },
    methods: {
        ...mapActions({
            updatePaymentDialogState: "updatePaymentDialogState",
            updateShowChargingDialog: "charge/updateShowChargingDialog",
            updateStartCharging: "charge/updateStartCharging",
            selectPinToBook: "bookingDialog/updateSelectedPin"
        }),
        handleCheckoutClick(route){
            if(this.generalTermsAccepted && this.selectedPaymentMethodObject){
                this.updatePaymentDialogState(true)
            }else{
                this.payBtnClicked = true
            }
        },
        catchInput(e){
            if (e < 1){
                this.duration = 1
                this.sliderKey++
            }
        },
        handleCarPlugged(){
            this.updateStartCharging(true)
            this.selectPinToBook({
                metadata: {
                    address : "",
                    description: "",
                    id: 0,
                    power: "22",
                    pricePerMinutes: 0.247,
                    rating: "4.9",
                    reviews: 14,
                    title: "Parking avenue Foch",
                    address: "Emplacement 1"
                },
                selectedPlug: this.selectedPlugId
            })
            this.updateShowChargingDialog(true)
        }
    },
    watch:{
        paymentDone(val){
            if(val === true){
                this.step = 3
            }
        }
    }
}
</script>
<style scoped>
.charger-wrapper {
    border: solid 1px #46b8a6;
}
.charger-wrapper.active{
    background: #46b8a6;
    color: white;
}
.slider{
    width: 100%;
}
.payment-methods-wrapper {
    height: 100%;
}
.payment-methods-footer-btn{
    width: 80%;
}
.payment-methods-footer-btn.disabled{
    opacity: 0.55;
}
</style>
